<template>
  <HomePage />
</template>

<script>

import HomePage from "@/components/HomePage.vue";

export default {
  name: 'App',
  components: {
    HomePage
  },
}
</script>

<style>
#app {
  margin: 0;
  padding: 0;
}
</style>
