<template>

  <div class="container">

    <div class="main-container">

      <div class="top-home screen-padding">
        <img src="../assets/logo.png" style="width: 200px; height: 40px;" class="logo">
        <div class="text-box">
          <p class="title">Ethereum(ETH) Ecosystem Smart Wallet</p>

          <div class="desc-box">
            <p>Ethereum (ETH) ecosystem smart wallet, intelligently calculates transaction fees, and enables lightning-fast transfers.</p>
          </div>


          <div class="top-home-left-container-op">

            <div class="main-menu">
              <img src="../assets/MainButton.png">
            </div>

            <div class="download-menu">
              <div class="google-play">
                <img src="../assets/GooglePlay.png">
              </div>
              <div class="app-store">
                <img src="../assets/AppStore.png">
              </div>
            </div>
          </div>

        </div>
      </div>


      <div class="phone-screen">
        <img src="../assets/2.png">
      </div>

      <div class="phone-screen">
        <img src="../assets/3.png">
      </div>


      <div class="phone-screen">
        <img src="../assets/4.png">
      </div>

      <div class="phone-screen">
        <img src="../assets/5.png">
      </div>

      <div class="phone-screen">
        <img src="../assets/6.png">
      </div>

      <div class="phone-screen">
        <img src="../assets/7.png">
      </div>

<!--      <div class="phone-screen">-->
<!--        <img src="../assets/screen-8.png">-->
<!--      </div>-->

<!--      <div class="phone-screen">-->
<!--        <img src="../assets/screen-9.png">-->
<!--      </div>-->

<!--      <div class="footer-text-box screen-padding">
        <p class="title">Start earning today</p>

        <div class="desc-box">
          <p>Download the app now and start investing immediately</p>
        </div>


        <div class="top-home-footer-container-op">
          <div class="download-menu">
            <div class="google-play">
              <img src="../assets/GooglePlay.png">
            </div>
            <div class="app-store">
              <img src="../assets/AppStore.png">
            </div>
          </div>
        </div>

      </div>-->

      <div class="screen-footer">
<!--        <div><img src="../assets/logo.png" style="width: 200px; height: 26px"></div>-->
        <div class="plus-font"><span>About</span></div>
        <div>
          <a href="/privacyPolicy">
            <span>Terms of Service</span>
            <br />
            <span>Privacy Policy</span>
          </a>
        </div>
        <div></div>
        <div class="plus-font"><span>Contact</span></div>
        <div><span>info@coinlinksec.com</span></div>
      </div>
      <div class="coinLinkSecureTech"><span>© 2024 CoinLink SecureTech Limited</span></div>


    </div>

  </div>

</template>

<script>

export default {
  name: 'HomePage',
  props: {
    msg: String
  }
}

</script>

<style>

.container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.main-container {
  width: 374px;
  margin-left: calc(50% - 186px);
  height: 100%;
}

.screen-padding {
  padding: 0 0;
}

.top-home {
  background: url('../assets/pc-background.svg') no-repeat;
  height: 100%;
  width: 100%;
  padding-top: 20px;
  overflow: hidden;
  background-size:cover;
}

.top-home .logo {
  margin-left: 20px;
}

.main-container .text-box {
  margin-top: 40px;
  height: 500px;
  width: 100%;
  //padding: 0 20px;
}

.text-box .title {
  font-family: PingFang SC,serif;
  font-size: 25px;
  line-height: 25px;
  margin-left: 20px;
}

.main-container .footer-text-box {
  height: 200px;
  width: 100%;
}


.footer-text-box .title {
  font-family: PingFang SC,serif;
  font-size: 40px;
  line-height: 45px;
}


.footer-text-box .desc-box {
  margin-top: 10px;
}

.desc-box {
  font-family: PingFang SC,serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0;
  color: #475766;
  word-wrap: break-word;
  margin-left: 20px;
}


.download-menu {
  margin-top: 40px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
}

  .top-home-left-container-op .main-menu {
    height: 61px;
    width: 374px;
  }

  .main-menu img {
    max-width: 354px;
    height: 61px;
    width: 100%;
    content: url('../assets/MainButton-374.svg');
  }


  .app-store {
    float: left;
    margin-left: 60px;
    width: 120px;
    height: 70px;
  }

  .app-store img {
    max-width: 120px;
    height: 40px;
  }

  .app-store:hover {
    cursor: pointer;
  }

  .google-play {
    float: left;
    width: 120px;
    height: 70px;
  }

  .google-play img {
    max-width: 120px;
    height: 40px;
  }

  .google-play:hover {
    //background: #b9426c;
    cursor: pointer;
  }

  .top-home-left-container-op {
    width: 100%;
    height: 300px;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }


.top-home-footer-container-op {
  width: 100%;
  height: 200px;
  margin-top: 20px;
}

  .desc-box {
    margin-top: 40px;
  }


  .phone-screen {
    width: 100%;
    height: 100%;
  }

  .phone-screen img {
    width: 374px;
    height: 100%;
  }

  .screen-footer {
    margin-top: -150px;
    width: 374px;
    margin-left: calc(50% - 187px);
    display: flex;
    height: 600px;
    //background-color: orange;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }


.coinLinkSecureTech {
  font-size: 25px;
  //line-height: 30px;
  font-weight: normal;
  text-align: center;
  margin-top: -60px;
  opacity: 1;
  color: #666666;
}



.screen-footer div {
  font-size: 25px;
  line-height: 49px;
  font-weight: normal;
  text-align: center;
  padding-top: 20px;
  opacity: 1;
  color: #666666;
}

.screen-footer .plus-font {
  font-weight: 600;
  font-size: 30px;
}

.screen-footer a:link, a:visited {
  color: #666666;
  text-decoration: none;
}

.screen-footer a:hover, a:active {
  color:#000000;
  text-decoration: underline;
}


@media screen and (min-width: 750px){
  .main-container {
    width: 750px;
    margin-left: calc(50% - 375px);
    height: 100%;
    //background: #42b983;
  }

  .screen-padding {
    //padding: 0 80px;
  }

  .main-container .text-box {
    //padding: 0 100px;
  }

  .desc-box p {
    font-size: 20px;
    line-height: 36px;
  }


  .text-box .title {
    font-family: PingFang SC,serif;
    font-size: 40px;
    line-height: 45px;
  }

  .main-container .footer-text-box {
    margin-top: -50px;
  }

  .top-home-left-container-op.main-menu {
    width: 710px;
  }

  .top-home-left-container-op {
    padding: 20px 20px 20px 20px;
    width: 100%;
  }

  .main-menu img {
    max-width: 710px;
    width: 710px;
    content: url('../assets/MainButton.png');
  }

  .download-menu {
    margin-top: 40px;
  }

  .google-play {
    height: 70px;
    width: 140px;
    margin-left: -70px;
  }

  .google-play img {
    max-width: 160px;
    width: 160px;
    height: 50px;
  }

  .app-store {
    margin-left: 170px;
    height: 70px;
    width: 140px;
  }


  .app-store img {
    max-width: 160px;
    width: 160px;
    height: 50px;
  }


  .phone-screen {
    margin-top: 0;
  }

  .phone-screen img {
    width: 750px;
    height: 100%;
  }

  .footer-text-box .title {
    margin-left: 30px;
  }

}

</style>